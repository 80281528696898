import React, { useState } from 'react';
import Slider from 'react-slick';
import tt from '../../helpers/translation';
import Icon from '../../helpers/icon';
import lokiSet from '../Loki/loki-icons';
import Buttons from '../Buttons/Buttons';
import './iconBox.scss';

const SampleNextArrow = properties => {
  const { className, style, onClick } = properties;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        zIndex: '100',
        right: '20px',
        background: 'white',
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-right" width="35" height="35" class="icon-arrow" iconSet={lokiSet} />
    </div>
  );
};

const SamplePrevArrow = properties => {
  const { className, style, onClick } = properties;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        zIndex: '100',
        left: '20px',
        background: 'white',
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" width="35" height="35" class="icon-arrow" iconSet={lokiSet} />
    </div>
  );
};

const settings = {
  centerMode: false,
  infinite: false,
  speed: 500,
  dots: true,
  dotsClass: 'dots-class',
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 459,
      settings: {
        slidesToShow: 1.2,
      },
    },
    {
      breakpoint: 505,
      settings: {
        slidesToShow: 1.3,
      },
    },
    {
      breakpoint: 539,
      settings: {
        slidesToShow: 1.4,
      },
    },
    {
      breakpoint: 569,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 611,
      settings: {
        slidesToShow: 1.6,
      },
    },
    {
      breakpoint: 689,
      settings: {
        slidesToShow: 1.7,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1.9,
      },
    },
    {
      breakpoint: 827,
      settings: {
        slidesToShow: 2.1,
      },
    },
    {
      breakpoint: 908,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 967,
      settings: {
        slidesToShow: 2.4,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2.6,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2.8,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 2.9,
      },
    },
    {
      breakpoint: 1165,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 3.2,
      },
    },
    {
      breakpoint: 1270,
      settings: {
        slidesToShow: 3.4,
      },
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 3.5,
      },
    },
  ],
};

const Card = ({ data, locale }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const vermas = (
    <>
      {open ? (
        <div className="verMas" onClick={handleOpen}>
          {tt('Leer menos', locale)}
        </div>
      ) : (
        <div className="verMas" onClick={handleOpen}>
          {tt('Leer más', locale)}
        </div>
      )}
    </>
  );
  return (
    <div className={`Card ${open ? 'flipped' : 'nonFlipped'}`}>
      <div className="front-card">
        <div className="imgCard">
          <img src={data.icon.url.src} alt={data.icon.alt} />
        </div>
        <div className="titleCard">{data.title}</div>
        {/* <div className="tisContent" dangerouslySetInnerHTML={{ __html: data.subtitle }} />

      {data.subtitle.length > 550 ? vermas : null} */}
        <Buttons onClick={handleOpen} size="alone" color="orange" text={tt('Leer más', locale)} />
      </div>
      <div className={`back-card ${data.subtitle.length > 550 ? 'long' : ''}`}>
        {/* <div className="imgCard">
          <img src={data.icon.url.src} alt={data.icon.alt} />
        </div> */}
        <div className="tisContent" dangerouslySetInnerHTML={{ __html: data.subtitle }} />
        <Buttons onClick={handleOpen} size="alone" color="orange" text={tt('Leer menos', locale)} />
      </div>
    </div>
  );
};

const CardMap = ({ blocks, locale }) => {
  const passingBlocks = blocks.map(element => {
    return <Card data={element} key={element.id} locale={locale} />;
  });

  return passingBlocks;
};

const SliderCardMap = ({ blocks, locale }) => {
  const passingBlocks = blocks.map(element => {
    return <Card data={element} key={element.id} locale={locale} />;
  });

  return <Slider {...settings}>{passingBlocks}</Slider>;
};

export const IconBox = ({ data: { blocks, title, subtitle, titleCarrousel }, locale }) => {
  return (
    <div className="IconBoxContainer">
      {title ? <div className="IconBoxTitle">{title}</div> : null}
      {subtitle ? <div className="IconBoxSubTitle">{subtitle}</div> : null}
      {titleCarrousel ? <div className="IconBoxTitle">{titleCarrousel}</div> : null}
      {/* <div className="IconBoxCardsContainer hide-tablet">
        <CardMap blocks={blocks} locale={locale} />
      </div> */}
      <div className="IconBoxCardsContainer">
        <SliderCardMap blocks={blocks} locale={locale} />
      </div>
    </div>
  );
};

export default IconBox;
