import React from 'react';
import './textImageSimple.scss';
import Buttons from '../Buttons/Buttons';
import tt from '../../helpers/translation';

const TextImageSimple = ({
  data: { title, subtitle, image, ctaText, cta, specialText },
  locale,
}) => {
  const videoES = 'https://www.youtube.com/watch?v=Zu7ENne7CJo';
  const videoEN = 'https://www.youtube.com/watch?v=CpxrjVzFvXw';
  const videoFR = 'https://www.youtube.com/watch?v=7G6Z2TdhAbQ';

  let url;
  if (locale === 'es' || locale === 'ca') {
    url = videoES.replace('watch?v=', 'embed/');
  } else if (locale === 'fr') {
    url = videoFR.replace('watch?v=', 'embed/');
  } else {
    url = videoEN.replace('watch?v=', 'embed/');
  }

  return (
    <div className="tisContainer">
      <div className="tisContainerText">
        {specialText && (
          <div className="specialText" dangerouslySetInnerHTML={{ __html: specialText }} />
        )}
        <h3 className="tisTitle">{title}</h3>
        {subtitle ? (
          <div className="tisContent" dangerouslySetInnerHTML={{ __html: subtitle }} />
        ) : null}
        <div className="tisButton">
          <Buttons link={cta} size="small" color="white" text={ctaText} />
        </div>
      </div>
      {/* <div className="tisImage"> */}
      {/* <iframe title="volvemos" className="tisVideo" width="100%" height="100%" src={url} /> */}
      {/* <img src={image.url.src} alt={image.alt} /> */}
      {/* </div> */}
    </div>
  );
};

export default TextImageSimple;
