import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Buttons from '../src/components/Buttons/Buttons';
import { Freya } from '../src/components/Freya/Freya';
import TitleH3 from '../src/components/Titles/TitleH3/TitleH3';
import TextImageSimple from '../src/components/textImageSimple/textImageSimple';
import IconBox from '../src/components/IconBox/iconBox';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';

import './healthy.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query healthy($locale: String!) {
    allHealthyRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
    allHealthySeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allHealthyJumboBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image
          alt
        }
      }
    }
    allHealthyTextContent(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
          localImage {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allHealthyCardsTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          titleSection
        }
      }
    }
    allHealthyCardsSubtitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          subtitle
        }
      }
    }
    allHealthyCardBlock(filter: { lang: { eq: $locale } }, sort: { fields: position, order: ASC }) {
      edges {
        node {
          position
          titleCard
          subtitleCard
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
    allHealthySecondCards(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
          description
        }
      }
    }
    allHealthySecondCardsContent(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          cta
          ctaText
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
    allHealthyBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allHealthySpecialText(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          content
        }
      }
    }
  }
`;

class Healthy extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allHealthyJumboBlock.edges[0].node.title,
      subtitle: this.props.data.allHealthyJumboBlock.edges[0].node.subtitle,
      image: {
        url: this.props.data.allHealthyJumboBlock.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allHealthyJumboBlock.edges[0].node.alt,
      },
    };

    const dataTextImageSimple = {
      title: this.props.data.allHealthyTextContent.edges[0].node.title,
      specialText: this.props.data.allHealthySpecialText.edges[0].node.content,
      subtitle: this.props.data.allHealthyTextContent.edges[0].node.subtitle,
      ctaText: tt('Más información', this.props.pageContext.locale),
      cta: tt('/informacion-importante', this.props.pageContext.locale),
      image: {
        url: this.props.data.allHealthyTextContent.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allHealthyTextContent.edges[0].node.alt,
      },
    };

    const data = {
      blocks: this.props.data.allHealthyCardBlock.edges.map((block) => ({
        position: block.node.position,
        title: block.node.titleCard,
        subtitle: block.node.subtitleCard,
        icon: {
          url: block.node.localImage.childImageSharp.fluid,
          alt: block.node.alt,
        },
      })),
    };
    const dataIconBox = {
      // afegim el titleCarrousel
      // title: this.props.data.allHealthyCardsTitle.edges[0].node.titleSection,
      // subtitle: this.props.data.allHealthyCardsSubtitle.edges[0].node.subtitle,
      titleCarrousel: this.props.data.allHealthyCardsTitle.edges[1].node.title,
      blocks: data.blocks.filter((e) => e.position >= '000010'),
    };
    const dataIconBox2 = {
      // Treiem title i subtitle y afegim el titleCarrousel
      titleCarrousel: this.props.data.allHealthyCardsTitle.edges[0].node.title,

      blocks: data.blocks.filter((e) => e.position < '000010'),
    };

    const freyaData = {
      cards: this.props.data.allHealthySecondCardsContent.edges.map((item) => ({
        image: item.node.localImage.childImageSharp.fluid,
        title: item.node.title,
        description: item.node.description,
        altImage: item.node.alt,
        imgsize: 'big',
        buttons: {
          cta: item.node.cta || false,
          size: item.node.cta ? 'alone' : '',
          color: item.node.cta ? 'white' : '',
          ctaText: item.node.ctaText,
        },
      })),
    };

    const separateInfoFAQ = freyaData.cards.filter((element) => element.buttons.cta !== false);
    const separateMedidas = freyaData.cards.filter((element) => element.buttons.cta === false);

    const freyaPreguntas = {
      cards: separateInfoFAQ,
    };
    const freyaMedidas = {
      cards: separateMedidas,
    };
    const titleData = {
      title: this.props.data.allHealthySecondCards.edges[0].node.title,
      subtitle: this.props.data.allHealthySecondCards.edges[0].node.subtitle,
      subtitle2: '',
      desc: this.props.data.allHealthySecondCards.edges[0].node.description,
    };
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allHealthySeoData.edges[0].node._0.title}
          description={this.props.data.allHealthySeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
          disabled
        />
        <Hod
          data={this.props.data.allHealthyRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allHealthyJumboBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="healthy-content">
            <div className="healthy-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allHealthyBreadcrumbBlock.edges[0].node.name}
              />
              <TextImageSimple data={dataTextImageSimple} locale={this.props.pageContext.locale} />
              <IconBox data={dataIconBox} locale={this.props.pageContext.locale} />
              <IconBox data={dataIconBox2} locale={this.props.pageContext.locale} />
              <div className="grayInfoTitle" id="faq">
                {tt('Preguntas frecuentes', this.props.pageContext.locale).toUpperCase()}
              </div>
              <div className="faq-container">
                <Freya data={freyaPreguntas} />
              </div>
              <div className="grayInfoContainer">
                <div className="grayInfoTitle">{titleData.title} </div>
                <div className="grayInfoSubTitle"> {titleData.subtitle2}</div>
                <div className="grayInfoSubTitle"> {titleData.subtitle}</div>
                <Freya data={freyaMedidas} />
                <div
                  className="grayInfoDesc"
                  dangerouslySetInnerHTML={{ __html: titleData.desc }}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Healthy;
